.wrapper-3d {
  position: relative;
    /* Based on screen with so the perspective doesn't break on small sizes*/
  perspective: 20vw;
  transform-style: preserve-3d;
}

.fold {
  overflow: hidden;
  width: 80vw;
  height: 80vh;
}

@media only screen and (max-width: 767px) {
  .fold {
    height: 80vh;
  }
}

.fold-after {
  position: absolute;
  transform-origin: top center;
  right: 0;
  left: 0;
  top: 100%;
}

.fold-before {
  position: absolute;
  transform-origin: bottom center;
  left: 0;
  right: 0;
  bottom: 100%;
}

.fold-align {
	width: 100%;
	height: 100%;
}

.fold-bottom .fold-align {
	transform: translateY(-100%);
}

.fold-top .fold-align {
	transform: translateY(100%);
}

.fold-bottom {
	transform-origin: top center;
	transform: rotateX(90deg);
}

.fold-top {
	transform-origin: bottom center;
	transform: rotateX(-90deg);
}

@media only screen and (max-width: 767px) {
  .fold-bottom {
    transform: rotateX(140deg);
  }

  .fold-top {
    transform: rotateX(-140deg);
  }
}
